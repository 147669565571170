import { useRouter } from "next/router";
import React, { Dispatch, ReactNode, SetStateAction, useContext } from "react";
import { api } from "../services/api";
import { AuthContext } from "../contexts/AuthContext";

interface IModelContext {
  isRechargeableWallet: boolean;
  isPoints: boolean;
  isProduct: boolean;
  tableNameFild: string;
  isAdminAndisRechargeableWallet: (isRechargeableWallet: boolean) => boolean;
  nameOfFirstFieldManager: (isAdminUser: boolean) => string;
  formateHomePageTextsforRechargeableWallet: (
    isRechargeableWallet: boolean,
    nameForRechargeableWallet: string,
    nameForPoints: string,
  ) => string;
  settypeOfLayaltProgram: Dispatch<SetStateAction<string>>;
  isLoadingLoyaltProgramModel: boolean;
}

interface IModelContextStorage {
  children: ReactNode;
}

interface UserCompany {
  company: {
    id: string;
  };
}

export const ModelContext = React.createContext({} as IModelContext);

export const ModelContextStorage = ({ children }: IModelContextStorage) => {
  const { query } = useRouter();
  const { user } = useContext<UserCompany | any>(AuthContext);
  const [typeOfLayaltProgram, settypeOfLayaltProgram] = React.useState("");
  const [loyaltProgramModel, setLoyaltProgramModel] = React.useState({
    isPoints: false,
    isRechargeableWallet: false,
    isProduct: false,
  });
  const [isLoadingLoyaltProgramModel, setIsLoadingLoyaltProgramModel] =
    React.useState(true);

  const isAdminAndisRechargeableWallet = (isAdminUser: boolean) => {
    return (
      isAdminUser === true && loyaltProgramModel.isRechargeableWallet === true
    );
  };

  const formateHomePageTextsforRechargeableWallet = (
    isRechargeableWallet: boolean,
    nameForRechargeableWallet: string,
    nameForPoints: string,
  ) => {
    if (isRechargeableWallet) {
      return nameForRechargeableWallet;
    } else {
      return nameForPoints;
    }
  };

  const nameOfFirstFieldManager = (isAdminUser: boolean) => {
    if (loyaltProgramModel.isPoints === true) {
      return "Pontos";
    } else if (
      isAdminUser === true &&
      loyaltProgramModel.isRechargeableWallet === true
    ) {
      return "Desconto";
    } else {
      return "Tipo";
    }
  };

  React.useEffect(() => {
    if (typeOfLayaltProgram === "product") {
      setLoyaltProgramModel({
        isPoints: false,
        isRechargeableWallet: false,
        isProduct: true,
      });
    }
    if (typeOfLayaltProgram === "value") {
      setLoyaltProgramModel({
        isPoints: true,
        isRechargeableWallet: false,
        isProduct: false,
      });
    }
    if (typeOfLayaltProgram === "wallet") {
      setLoyaltProgramModel({
        isPoints: false,
        isRechargeableWallet: true,
        isProduct: false,
      });
    }
  }, [typeOfLayaltProgram]);

  React.useEffect(() => {
    const getLoyaltyProgramByCompanyId = async (
      companyId: string | unknown,
    ) => {
      try {
        setIsLoadingLoyaltProgramModel(true);
        const { data } = await api.get("/loyalty-program/info/" + companyId);
        const type = data?.result?.type;
        if (type) settypeOfLayaltProgram(type);
      } catch (err) {
        console.log("Error ao buscar loyaltProgram: ", err);
      } finally {
        setIsLoadingLoyaltProgramModel(false);
      }
    };

    if (query.companyId) {
      getLoyaltyProgramByCompanyId(query.companyId);
    } else if (user?.company?.id) {
      getLoyaltyProgramByCompanyId(user.company.id);
    }
  }, [query.companyId, user?.company?.id]);

  React.useEffect(() => {
    console.log("LOYALT PROGRAM", typeOfLayaltProgram);
  }, [typeOfLayaltProgram]);

  const value = {
    isRechargeableWallet: loyaltProgramModel.isRechargeableWallet,
    isPoints: loyaltProgramModel.isPoints,
    isProduct: loyaltProgramModel.isProduct,

    nameOfFirstFieldManager,
    isAdminAndisRechargeableWallet,
    tableNameFild: formateHomePageTextsforRechargeableWallet(
      loyaltProgramModel.isRechargeableWallet,
      "Saldo MT",
      "Pontos",
    ),
    formateHomePageTextsforRechargeableWallet,
    isLoadingLoyaltProgramModel,
    settypeOfLayaltProgram,
    typeOfLayaltProgram,
  };
  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
};
