import React, { useState, useEffect } from "react";
import Head from "next/head";
import Router from "next/router";
import PropTypes from "prop-types";
import theme from "../styles/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import Loader from "../components/modals/Loader";
import { ModelContextStorage } from "../contexts/ModelContext";
import { AuthProvider } from "../contexts/AuthContext";

import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "../styles/datepicker.css";
import "../styles/globals.css";
import Script from "next/script";

const MyApp = ({ Component, pageProps }) => {
  const Layout = Component.Layout ? Component.Layout : React.Fragment;
  const [isLoading, setLoading] = useState(false);

  Router.events.on("routeChangeStart", (url) => {
    console.log(`Loading... ${url}`);
    setLoading(true);
  });

  Router.events.on("routeChangeComplete", (url) => {
    console.log(`Called... ${url}`);
    setLoading(false);
  });

  Router.events.on("routeChangeError", (url) => {
    console.log(`Error calling... ${url}`);
  });

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    let bars = document.getElementsByTagName("header");
    let selectedBar = bars && bars.length > 0 ? bars[0] : null;
    if (!isLoading) {
      if (selectedBar) {
        selectedBar.style.display = "block";
      }
    }
  }, [isLoading]);

  return (
    <>
     <Script
        src={`https://www.googletagmanager.com/gtag/js?id=G-WQ7DB612VT`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WQ7DB612VT');
    `}
      </Script>
      <AuthProvider>
      <React.Fragment>
        <Head>
          <title>Loyalty Engine Admin | Bem-Vindo</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <link rel="shortcut icon" href="/favicon.png" />
        </Head>
        <ThemeProvider theme={theme}>
          <ModelContextStorage>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Layout>
              {isLoading && <Loader />}
              <Component {...pageProps} />
            </Layout>
          </ModelContextStorage>
        </ThemeProvider>
      </React.Fragment>
    </AuthProvider>
    </>
  
  );
};

export default MyApp;
MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
