import { differenceInDays, format } from "date-fns";
import { parseCookies } from "nookies";

// Default export for the front-end use
const parseDate = (date) => {
  if (typeof date === "undefined" || date === null || date.length === 0) {
    return "";
  }

  const newDate = date.split("T")[0];
  const day = newDate.split("-")[2];
  const month = newDate.split("-")[1];
  const year = newDate.split("-")[0];

  const newTime = date.split("T")[1];
  const fullTime = newTime.split(".")[0];

  const hour = fullTime.split(":")[0];
  const minutes = fullTime.split(":")[1];
  const seconds = fullTime.split(":")[2];
  const time = hour + ":" + minutes + ":" + seconds;
  return day + "/" + month + "/" + year + ",  " + time;
};

function ParseDateDivider(date) {
  const dateParsed = parseDate(date);
  const dateString = dateParsed.split(",");
  return {
    date: dateString[0].trim(),
    hour: dateString[1].trim().slice(0, 5),
  };
}

function convertDateWithLib(date) {
  return format(new Date(date), "dd/MM/yyyy, HH:mm:ss");
}

const getSince = (date) => {
  if (typeof date === "undefined" || date === null || date.length === 0) {
    return "";
  }
  const newDate = date.split("T")[0];
  const day = newDate.split("-")[2];
  const month = newDate.split("-")[1] - 1;
  const year = newDate.split("-")[0];

  let months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return `${months[parseInt(month)]} de ${year}`;
};

const parseBirthDate = (date) => {
  if (typeof date === "undefined" || date === null || date.length === 0) {
    return "";
  }
  const newDate = date.split("T")[0];
  const day = newDate.split("-")[2];
  const month = newDate.split("-")[1] - 1;
  const year = newDate.split("-")[0];

  let months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return `${day} ${months[parseInt(month)]} de ${year}`;
};

const parseBirthDateToField = (date) => {
  if (typeof date === "undefined" || date === null || date.length === 0) {
    return "";
  }

  const newDate = date.split("T")[0];
  const day = newDate.split("-")[2];
  const month = newDate.split("-")[1];
  const year = newDate.split("-")[0];

  return year + "-" + month + "-" + day;
};

const calcPercetage = (total, percetageX) => {
  const result = (parseFloat(total) * parseFloat(percetageX)) / 100;
  const response = parseFloat(result) + "";
  return response.split(".")[1];
};

const getMonthTextByIndex = (index) => {
  let months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  return months[index];
};

const prepareDatePickerDate = (startDate, endDate) => {
  const monthsLower = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  let finalOBJ = {
    startDate: startDate,
    endDate: endDate,
  };

  const startDateStr = finalOBJ.startDate;
  const endDateStr = finalOBJ.endDate;

  const splitedStartMonth = startDateStr.toString().split(" ")[1].trim().toLowerCase();
  const splitedEndMonth = endDateStr.toString().split(" ")[1].trim().toLowerCase();

  const indexStartMonth = monthsLower.indexOf(splitedStartMonth) + 1;
  const indexEndMonth = monthsLower.indexOf(splitedEndMonth) + 1;

  const monthStringStart = indexStartMonth >= 10 ? indexStartMonth : `0${indexStartMonth}`;
  const monthStringEnd = indexEndMonth >= 10 ? indexEndMonth : `0${indexEndMonth}`;

  finalOBJ.startDate = `${startDateStr.toString().split(" ")[2]}/${monthStringStart}/${startDateStr.toString().split(" ")[3]}`;
  finalOBJ.endDate = `${endDateStr.toString().split(" ")[2]}/${monthStringEnd}/${endDateStr.toString().split(" ")[3]}`;

  return finalOBJ;
};

const canBeCancelled = (theDate) => {
  const today = new Date();
  const custom = new Date(theDate); //"2021-12-31T16:00:00Z"
  console.log(today, custom);
  const diff = differenceInDays(today, custom);
  const status = typeof diff === "number" && diff > 7 ? false : true;
  return status;
};

const isValidId = (value) => {
  // the next regex start with numbers ou characters and length is more than 20
  var idPattern = /^[a-zA-Z0-9][a-zA-Z0-9_$]{19,}$/;
  if (typeof value === "string" && idPattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

const chooseCompanyId = (routerQueryId, contextCompanyId) => {
  if (isValidId(routerQueryId)) {
    return routerQueryId;
  }
  if (isValidId(contextCompanyId)) {
    return contextCompanyId;
  }
  const { "company.Id": companyId } = parseCookies();
  return companyId;
};



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isValidId,
  chooseCompanyId,
  parseDate,
  ParseDateDivider,
  getSince,
  parseBirthDateToField,
  parseBirthDate,
  calcPercetage,
  getMonthTextByIndex,
  prepareDatePickerDate,
  canBeCancelled,
  convertDateWithLib,
};
